import { Box, Typography } from "@mui/material";
import uiucExtension from '../../images/uiuc-extension.jpg';
import { useAppSelector } from "../../state/hooks";

export const FoodPantryFinder = () => {
  	const userInfo = useAppSelector((state: any) => state.userInfo);
	return (
		<Box display="flex" sx={{ alignItems: "center", justifyContent: "center"}} >
			<Box display={"flex"} sx={{
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					marginTop: 5,
					marginBottom: 20,
				}}
			>
				<Typography variant="h2" fontWeight="700" color="var(--pine)">Hello {userInfo.userObj.gardenerProfile.name !== "" ? userInfo.userObj.gardenerProfile.name : "Gardener"}!</Typography>
				<Typography variant="h6" fontWeight="500" color="var(--pine)">Welcome to Find-A-Pantry</Typography>
				<ol style={{color: "var(--pine)"}}>
					<li>Click the link below.</li>
					<li>Allow access to location to bring you to Peoria automatically.</li>
					<li>{"At the top of the page, go to Filters > Food Pantries & Meal Sites"}</li>
					<li>You'll be able to see a list of Food Pantries in the Peoria area!</li>
					<li>Click the image below to open FoodFinder (provided by the U of I Extension) in a new tab!</li>
				</ol>
				<a href={"https://foodfinder-il-widget.web.app/"} target={"_blank"}><img src={uiucExtension} style={{borderRadius: "5px"}}/></a>
			</Box>
		</Box>
	);
}